import { TweenMax, TimelineMax, Power2 } from 'gsap'
import ScrollMagic from 'scrollmagic';
import { ScrollMagicPluginGsap } from 'scrollmagic-plugin-gsap'
ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax)

import AOS from 'aos';

export default class Animations {
    constructor () {
        this._section = '.section'
        this._initAnimation();
    }

    _initAnimation () {
        let ctrl = new ScrollMagic.Controller();
        $(this._section).each(function(i) {
            let animatedItem = $(this).find('.animated-item')
            let animatedItemBottom = $(this).find('.animated-item-bottom')
            
            let timeLine = new TimelineMax();
            timeLine.staggerFromTo(animatedItem, .8, { opacity: 0 }, {opacity: 1, ease: Power2.easeInOut}, .1, .25)
            timeLine.staggerFromTo(animatedItemBottom, .8, { opacity: 0, y: '50px' }, {opacity: 1, y: '0', ease: Power2.easeInOut}, .1, .30)
            
            new ScrollMagic.Scene({
                triggerElement: this,
                triggerHook: 0.84,
                reverse: 1
            }).setTween(timeLine).addTo(ctrl)
        });

        AOS.init({ mirror: false });

        $(document).on('click', 'a[href^="#"]', function(e) {
            let id = $(this).attr('href');

            let $id = $(id);
            if ($id.length === 0) {
                return;
            }

            e.preventDefault();

            let pos = $id.offset().top - 100;

            $('body, html').animate({scrollTop: pos});
        })
    }
}