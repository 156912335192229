import 'lightgallery';
export default class BluebookGallery {
    constructor () {
        this.btnBluebook = $('.btn-bluebook-pdf');
        this._initLightGallery();
    }
    
    _initLightGallery () {
        $(this.btnBluebook).on('click', function(e) {
            $(e.currentTarget).lightGallery({
                // selector: '.swiper-slide',
                download: false,
                dynamic: true,
                dynamicEl: [
                    {
                        src: 'images/bluebook/overview-01.jpg'
                    },
                    {
                        src: 'images/bluebook/overview-02.jpg'
                    },
                    {
                        src: 'images/bluebook/overview-03.jpg'
                    },
                    {
                        src: 'images/bluebook/overview-04.jpg'
                    },
                    {
                        src: 'images/bluebook/overview-05.jpg'
                    },
                    {
                        src: 'images/bluebook/overview-06.jpg'
                    },
                    {
                        src: 'images/bluebook/overview-07.jpg'
                    },
                    {
                        src: 'images/bluebook/overview-08.jpg'
                    },
                    {
                        src: 'images/bluebook/overview-09.jpg'
                    },
                    {
                        src: 'images/bluebook/overview-10.jpg'
                    },
                    {
                        src: 'images/bluebook/overview-11.jpg'
                    },
                    {
                        src: 'images/bluebook/overview-12.jpg'
                    },
                    {
                        src: 'images/bluebook/overview-13.jpg'
                    },
                    {
                        src: 'images/bluebook/overview-14.jpg'
                    },
                    {
                        src: 'images/bluebook/overview-15.jpg'
                    },
                    {
                        src: 'images/bluebook/overview-16.jpg'
                    },
                    {
                        src: 'images/bluebook/overview-17.jpg'
                    },
                    {
                        src: 'images/bluebook/overview-18.jpg'
                    },
                    {
                        src: 'images/bluebook/overview-19.jpg'
                    },
                    {
                        src: 'images/bluebook/overview-20.jpg'
                    },
                    {
                        src: 'images/bluebook/overview-21.jpg'
                    },
                    {
                        src: 'images/bluebook/overview-22.jpg'
                    }
                ]
            });
        })
    }

}