export default class ImageSwap {
    constructor () {
        this.$gifImage = $('.js-image-swap')
        this.$imageLink = $('.js-image-link')
        this._initAnimation();
    }

    _initAnimation () {
        this.$imageLink.on('click', this.hanleImageClick.bind(this))
    }

    hanleImageClick (e) {
        let $this = $(e.currentTarget)
        let activeImage = $this.data('gif')
        this.$imageLink.not($this).removeClass('active')
        $this.addClass('active')
        this.$gifImage.fadeOut('fast', function () {
            $(this).attr('src', activeImage)
            $(this).fadeIn('fast')
        })
    }
}