import CookiesGDPR from './modules/CookiesGDPR';
import Animations from './modules/Animations';
import Counter from './modules/Counter';
import HeaderAnimation from './modules/HeaderAnimation';
import ImageSwap from './modules/ImageSwap';
import SwiperTestimonials from './modules/SwiperTestimonials';
import SwiperPartners from './modules/SwiperPartners';
import SwiperTimeline from './modules/SwiperTimeline';
import Tabs from './modules/Tabs';
import BluebookGallery from './modules/BluebookGallery';
import Accordion from './modules/Accordion';
import ScrollToSection from './modules/ScrollToSection';

export default class App {
    static init () {
        new CookiesGDPR;
        new Animations;
        new Counter;
        new HeaderAnimation;
        new ImageSwap;
        new SwiperTestimonials;
        new SwiperPartners;
        new SwiperTimeline;
        new Tabs;
        new BluebookGallery;
        new Accordion;
        new ScrollToSection;
    }
}
