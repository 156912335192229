export default class ScrollToSection {
    constructor () {
        this.$href = '[title]';
        this._initScroll();
    }

    _initScroll () {
        $(document).on('click', this.$href, this.handleHrefClick.bind(this))
        this.scrollToSection()
        this.scrollIntoView()
    }

    handleHrefClick (e) {
        const $this = $(e.currentTarget)
        const sectionLocation = $this.attr('title')
        const url = $this.attr('href')
        localStorage.setItem('section-location', sectionLocation)

        if (sectionLocation) {
            const section = $(`#${sectionLocation}`);
            let sectionOffset = 150;

            if (section.is('.blue-bg')) {
                sectionOffset = 0
            }

            $('html, body').animate({ scrollTop: section.offset().top - sectionOffset }, 800);

            return false;
        }

        window.location = url
    }

    scrollToSection () {
        const activeSection = localStorage.getItem('section-location')

        if(activeSection) {
            const section = $(`#${activeSection}`);
            let sectionOffset = 150;

            if (section.is('.blue-bg')) {
                sectionOffset = 0
            }

            setTimeout(() => {
                $('html, body').animate({ scrollTop: section.offset().top - sectionOffset }, 800);
                localStorage.removeItem('section-location')
            }, 1500);
        }
    }

    scrollIntoView () {
        $(window).on('scroll', function () {
            $('.section-bluebook').each(function () {
                const $elem = $(this);
                const $window = $(window);
                const docViewTop = $window.scrollTop();
                const docViewBottom = docViewTop + $window.height();
                const elemTop = $elem.offset().top;
                const elemBottom = elemTop + $elem.height();
                const sectionID = $elem.attr('id');

                if ((elemTop <= docViewBottom) && (elemBottom >= docViewTop) === true) {
                    $(`[title]`).not(`[title=${sectionID}]`).parent().removeClass('is-active')
                    $(`[title=${sectionID}]`).parent().addClass('is-active')
                }
            });
        });
    }
}
