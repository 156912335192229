import Swiper from 'swiper/swiper-bundle';
import { MAIN_SWIPER_OPTIONS } from '../configs/swiper.config';
export default class Counter {
    constructor () {
        this.section = $('.section-counter')
        this._initAnimation();
    }

    _initAnimation () {
        let a = 0;
        $(window).on('scroll', function () {
            if($('.section-counter').length > 0) {
                let oTop = ($('.section-counter').offset().top + 200) - window.innerHeight
                if (a == 0 && $(window).scrollTop() > oTop) {
                    $('.counter-number').each(function () {
                        let $this = $(this);
                        $({ Counter: 0 }).animate({ Counter: $this.attr('data-number') }, {
                            duration: 4000,
                            easing: 'swing',
                            step: function () {
                                $this.text(Math.ceil(this.Counter));
                            }
                        });
                    });
                    a = 1;
                }
            }
        });

        if($(window).width() < 768) {
            $('.counter-grid').addClass('swiper-wrapper')
            $('.counter-item').addClass('swiper-slide')
            this.section.find('.container').addClass('swiper-container')
            const counterSwiper = new Swiper('.section-counter .swiper-container', MAIN_SWIPER_OPTIONS)
            counterSwiper.on('slideChange', function () {
                let activeNumber = $('.swiper-slide-active').find('.counter-number')
                $({ Counter: 0 }).animate({ Counter: activeNumber.attr('data-number') }, {
                    duration: 4000,
                    easing: 'swing',
                    step: function () {
                        activeNumber.text(Math.ceil(this.Counter));
                    }
                });
            });
        }
    }
}