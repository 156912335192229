import 'gdpr-cookie'
export default class gdprCookie {
    constructor () {
        this.init();
    }

    init() {
        $.gdprcookie.init({
            title: 'SAT Health uses cookies!',
            message: `Learn more about our <a href="/cookie-policy">Cookie policy</a>`,
            acceptBtnLabel: 'Accept All',
            cookieTypes: [
                {
                    type: "Essential",
                    value: "essential",
                    description: "These are cookies that are essential for the website to work correctly.",
                    checked: true,
                },
                {
                    type: 'Analytics',
                    value: 'analytics',
                    description: "Cookies related to site visits, browser types, etc.",
                    checked: true
                },
            ]
        });

        $('body').on('gdpr:accept', function() {
            location.reload();
        });

        $(document).on('change', '.gdprcookie input', function() {
            $('.gdprcookie button:first').text('Accept')
        });
    }
}
