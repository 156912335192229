export const MAIN_SWIPER_OPTIONS = {
    slidesPerView: 1,
    simulateTouch: false,
    loop: true,
    effect: 'fade',
    autoplay: {
        delay: 2500,
    },
    fadeEffect: { 
        crossFade: true 
    },
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
};

export const TESTIMONIALS_SWIPER_OPTIONS = {
    slidesPerView: 1,
    simulateTouch: false,
    loop: true,
    autoplay: {
        delay: 2500,
    },
    effect: 'fade',
    fadeEffect: { 
        crossFade: true 
    },
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    pagination: {
        el: '.swiper-pagination',
    },
    breakpoints: {
        0: {
            autoHeight: true
        },
        768: {
            autoHeight: false
        }
    },
};

export const PARTNERS_SWIPER_OPTIONS = {
    slidesPerView: 1,
    spaceBetween: 0,
    loop: true,
    watchSlidesProgress: true,
    watchSlidesVisibility: true,
    autoplay: {
        delay: 3500,
    },
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    breakpoints: {
        640: {
            slidesPerView: 2,
            spaceBetween: 10,
        },
        768: {
            slidesPerView: 4,
            spaceBetween: 30,
        },
        1024: {
            slidesPerView: 5,
            spaceBetween: 50,
        },
    }
};

export const SERVICES_SWIPER_OPTIONS = {
    watchSlidesProgress: true,
    watchSlidesVisibility: true,
    slidesPerView: 3,
    simulateTouch: false,
    spaceBetween: 40,
    autoplay: {
        delay: 2500,
    },
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    breakpoints: {
        0: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        685: {
          slidesPerView: 2,
        },
        1024: {
          slidesPerView: 3,
        }
    },
}

export const TIMELINE_SWIPER_OPTIONS = {
    slidesPerView: 'auto',
    centeredSlides: true,
    autoplay: true,
    spaceBetween: 50,
    observer: true,
    watchSlidesProgress: true,
    watchSlidesVisibility: true,
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
        disabledClass: 'is-disabled',
    }
};
