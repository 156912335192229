export default class Tabs {
    constructor () {
        this.tab = $('.js-tab');
        this.tabsContainer = $('.js-tab-container');
        this.init();
    }

    init () {
        this.tab.on('click', this.toggleActive.bind(this));
    }

    toggleActive(e) {
        let $this = $(e.currentTarget);
        let dataID = $this.data('id');
        $('.js-tab').removeClass('is-active');
        $this.addClass('is-active');
        this.tabsContainer.hide();
        $(`#${dataID}`).show();
    }
}