import Swiper from 'swiper/swiper-bundle';
import { TIMELINE_SWIPER_OPTIONS } from '../configs/swiper.config';

export default class SwiperTimeline {
    constructor () {
        this.timelineContainer = '.swiper-timeline';
        this._initSwiper();
    }

    _initSwiper () {
        const swiper = new Swiper(this.timelineContainer, TIMELINE_SWIPER_OPTIONS)
        if(swiper.length > 0) {
            swiper.map((el) => {
                el.on('reachEnd', function () {
                    const nextActiveTab = document.querySelector('.js-tab.is-active').nextElementSibling
                    if (nextActiveTab) {
                        setTimeout(() => {
                            nextActiveTab.click()
                        }, 2500)
                    }
                })
            })
        }
    }
}