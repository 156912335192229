import Swiper from 'swiper/swiper-bundle';
import { PARTNERS_SWIPER_OPTIONS } from '../configs/swiper.config';

export default class SwiperPartners {
    constructor () {
        this.partnersContainer = '.swiper-partners';
        this._initSwiper();
    }

    _initSwiper () {
        new Swiper(this.partnersContainer, PARTNERS_SWIPER_OPTIONS)
    }
}